import React from "react";

import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const Testimonials = () => {
  const submittedTestimonials = useStaticQuery(graphql`
    query TestimonialsTwoSubmissionsQuery {
      allFile(filter: { relativeDirectory: { eq: "testimonials" } }) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                title
                submitted_via
                submitted_by
                contact_information
                message
                photo {
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `).allFile.edges;

  return (
    <section className={"sm:px-4 overflow-hidden"}>
      <CarouselProvider
        totalSlides={submittedTestimonials.length}
        naturalSlideHeight={100}
        naturalSlideWidth={200}
        isIntrinsicHeight={true}
        visibleSlides={2}
        infinite
        isPlaying
        interval={4000}
        className={"container mx-auto"}
      >
        <Slider>
          {submittedTestimonials.map(({ node }, i) => (
            <Slide
              key={node.id}
              index={i}
              innerClassName={"flex flex-col justify-center"}
            >
              <TestimonialCard
                key={node.id}
                message={node.childMarkdownRemark.frontmatter.message}
                photo={node.childMarkdownRemark.frontmatter.photo}
                submittedBy={node.childMarkdownRemark.frontmatter.submitted_by}
                submittedVia={
                  node.childMarkdownRemark.frontmatter.submitted_via
                }
              />
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </section>
  );
};

export const TestimonialCard = ({
  message,
  photo,
  submittedBy,
  submittedVia,
}) => {
  return (
    <div className={"px-2"}>
      <div className="max-w-md py-4 px-4 md:px-8 bg-gradient shadow-lg rounded-lg mt-20 mb-4">
        <div className="flex justify-center md:justify-end -mt-16">
          <Img
            className="w-20 h-20 object-cover rounded-full border-2 border-gray-100 bg-white"
            fluid={photo.childImageSharp.fluid}
            alt={submittedBy}
            imgStyle={{ objectFit: "contain", pointerEvents: "none" }}
          />
        </div>
        <div>
          {/*<h2 className="text-gray-800 text-3xl font-semibold">{submittedVia}</h2>*/}
          <p className="mt-2 text-white antialiased text-sm md:text-base">
            {message}
          </p>
        </div>
        <div className="flex justify-end mt-4">
          <p className="text-xl font-medium text-accent antialiased">
            {submittedBy}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
